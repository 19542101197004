import React, {Component} from "react";
import {Form} from "reactstrap";
import {Field, reduxForm} from "redux-form";
import {
	FieldTxt,
	FieldCheckbox,
	FieldSelect,
	FieldSelect2
} from "../../components/Form/wh-field";
import {ModalBody, ModalFooter, Alert} from "reactstrap";
import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import * as memberService from "../../services/member";
import * as sectorService from "../../services/sectors";
import {required} from "@/components/Form/wh-validator";

class TeamForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			usersLoaded: false,
			sectors: [],
			sectorsLoaded: false
		};
	}

	loadUsers = async () => {
		this.setState({
			usersLoaded: false
		});

		return memberService
			.search({
				filtered: [
					{id: "pagination", value: false},
					{id: "enabled", value: true}
				]
			})
			.then(data => {
				this.setState({
					users: data["hydra:member"].map(user => ({
						value: user["@id"],
						name: `${user.firstname} ${user.lastname}`,
						label: `${user.firstname} ${user.lastname}`
					})),
					usersLoaded: true
				});
			});
	};

	loadSectors = async () => {
		this.setState({
			sectorsLoaded: false
		});

		return sectorService
			.search({
				filtered: [
					{id: "pagination", value: false},
					{id: "enabled", value: true},
					{id: "sectorType", value: this.props.sectorType}
				]
			})
			.then(data => {
				this.setState({
					sectors: data["hydra:member"].map(sector => ({
						value: sector["@id"],
						name: sector.name
					})),
					sectorsLoaded: true
				});
			});
	};

	componentDidMount = () => {
		this.loadUsers();
		this.loadSectors();
	};

	render() {
		const {error, handleSubmit, submitting} = this.props;
		const {users, sectors} = this.state;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					<Field
						label="Nom :"
						name="name"
						component={FieldTxt}
						type="text"
						validate={required}
					/>
					<Field
						label="Secteur :"
						name="sector"
						component={FieldSelect}
						options={sectors}
						required={false}
						validate={required}
					/>
					<Field
						label="Responsable :"
						name="userMaster"
						component={FieldSelect}
						options={users}
						required={false}
					/>
					<Field
						label="Membres :"
						name="users"
						component={FieldSelect2}
						options={users}
					/>
					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{__html: error}}/>
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						<i className="fa fa-save"/>
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

TeamForm = reduxForm({
	form: "TeamForm"
})(TeamForm);

export default TeamForm;
