import familyTypes from './types';
import { SET_FAMILY } from './constants';
import { init, search, remove, view, create, update } from '../../../../services/catalog/family';
import { SET_DATA, SET_PAGES, SET_LOADED, SET_EDITED, REMOVE_ITEM } from '../../redux/constants';
import { createAction } from 'redux-friends';
import formatDataForRedux from '@/utils/formatDataForRedux';

export const setFamily = createAction(familyTypes[SET_FAMILY]);
export const setFamilyLoadedState = createAction(familyTypes[SET_LOADED]);
export const setFamilyList = createAction(familyTypes[SET_DATA]);
export const setFamilyPages = createAction(familyTypes[SET_PAGES]);
export const setEditedFamilyItem = createAction(familyTypes[SET_EDITED]);
export const removeFamilyItem = createAction(familyTypes[REMOVE_ITEM]);

export const fetchFamily = () => async dispatch => {
	try {
		const data = await init();
		dispatch(setFamily(formatDataForRedux(data, 20)));
	} catch (error) {
		console.error('fetchFamily', { error });
		throw new Error(error);
	}
};

export const loadFamily = (state = {}) => async dispatch => {
	try {
		state.filtered = []
		const pageSize = state.filtered.find(x => x.id === 'pageSize')
		if (pageSize) state.pageSize = pageSize.value
		const data = await search(state);
		dispatch(setFamily(formatDataForRedux(data, state.pageSize)));
	} catch (error) {
		console.error({ error });
	}
};

export const deleteFamilyItem = toDelete => async dispatch => {
	try {
		await remove(toDelete);
		dispatch(removeFamilyItem(toDelete));
	} catch (error) {
		console.error({ error });
		alert('Il existe des dépendances sur cette famille, elle ne peut donc être supprimée');
	}
};

export const createFamilyItem = item => async dispatch => {
	try {
		await create(item);
		dispatch(loadFamily());
	} catch (error) {
		console.error({ error });
	}
};
export const updateFamilyItem = item => async dispatch => {
	try {
		await update(item);
		dispatch(loadFamily());
	} catch (error) {
		console.error({ error });
	}
};

export const fetchFamilyItem = id => async dispatch => {
	try {
		const data = await view(id);
		dispatch(setEditedFamilyItem(data));
	} catch (error) {
		console.error({ error });
	}
};

export const resetFamilyItem = () => async dispatch => {
	try {
		const data = {
			enabled : true
		}
		dispatch(setEditedFamilyItem(data));
	} catch (error) {
		console.error({ error });
	}
};

//
