import React, {Component} from "react";
import {Button, Card, CardHeader} from "reactstrap";
import WHTable from "../../../components/WHTable/index";
import 'moment/locale/fr';
import * as appointmentService from "../../../services/appointment";
import moment from "moment";
import * as Members from "@/services/member";
import Select from "react-select";
import {DateField} from "../../../components/Form/wh-field";
import {getUser, getUserRoles} from "../../Authentication/redux";
import {connect} from "react-redux";
import {redirectTo} from "../../../utils/redirectTo";
import {CONTRACTS} from "../../../constants/paths";

class TechnicianAppointmmentList extends Component {

	state = {
		data: [],
		pages: null,
		loaded: true,
		technicians: [],
		technician: null,
		startDate: null,
		endDate: null,
		tableState: null,
		totalAppointment: null,
	};


	columns = () => {
		return [
			{
				Header: "Nom",
				accessor: "contract.customer.lastName",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value}
					</div>
				)
			},
			{
				Header: "Prénom",
				accessor: "contract.customer.firstName",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value}
					</div>
				)
			},
			{
				Header: "Adresse",
				Cell: row => {
					const customer = row.original.contract ? row.original.contract.customer : undefined
					const customerAdress = customer ? `${customer.address} ${customer.city} ${customer.zipCode}` : ""
					return(
						<div className="text-center cell-cursor"
							 onClick={() =>
								 redirectTo(CONTRACTS, row.original.contract.id)
							 }
						>
							{ customerAdress }
						</div>
					)
				},
			},
			{
				Header: "Téléphone",
				accessor: "contract.customer.mobile",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value}
					</div>
				)
			},
			{
				Header: "Technicien",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.original.technician ? row.original.technician.name: ""}
					</div>

				)
			},
			{
				Header: "Numéro de l'entretien",
				accessor: "maintenanceIndex",
				Cell: row => {
					return(

					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)

						 }
					>
						{row.value}{row.value === 1 ? 'er' : 'ème'}
					</div>)}
			},
			{
				Header: 'Date',
				accessor: 'startDate',
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{moment(row.value).format('DD/MM/YYYY')}
					</div>
				),
			},
		]
	};

	load = (state) => {
		const isTechnicien = (this.props.roles.includes("ROLE_TECHNICIEN_SAV") || this.props.roles.includes("ROLE_TECHNICO_COMMERCIAL")) && !this.props.roles.includes("ROLE_ADMIN")

		const customFilters = state.customFilters ? state.customFilters : this.getCustomFiltersWithState(this.state)

		this.setState({
			loaded: false,
			tableState: state,
		});
		const technicien = isTechnicien ? [{id: 'technician', value: this.props.user.id}] : []
		return appointmentService.search({
			...state,
			filtered: [{id: 'technician', value: "IS NOT NULL"}, ...state.filtered, ...customFilters, ...technicien  ],
		}).then(data => {
			this.setState(
				{
					data: []
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize
						),
						totalAppointment: data["hydra:totalItems"],
						loaded: true,
					});
				}
			);
		});
	};

	getCustomFiltersWithState(newState) {
		const state = newState ? newState : this.state
		const customFilters = []

		const {technician, startDate, endDate} = state

		if (technician) {
			customFilters.push({id: 'technician', value: technician.value})
		}

		if (startDate) {
			customFilters.push({id: 'startDate[after]', value: moment(startDate).format('YYYY-MM-DD')})
		}

		if (endDate) {
			customFilters.push({id: 'startDate[before]', value: moment(endDate).format('YYYY-MM-DD')})
		}

		customFilters.push({id: 'order[startDate]', value: 'asc'})

		return customFilters
	}

	filterReload = (newState) => {
		const {tableState} = newState

		const customFilters = this.getCustomFiltersWithState(newState)

		const newTableState = {
			...tableState,
			customFilters,
			page: 0,
		}

		this.load(newTableState)
	}

	onFilterChange = (stateKey, value) => {
		const newState = {
			...this.state,
			[stateKey]: value,
		}

		this.setState(newState)

		this.filterReload(newState)
	}

	fetchTechnicians = () => {
		Members.search({
			filtered: [{id: "roles", value: "TECHNICIEN_SAV"}, {
				id: 'pagination',
				value: false
			}]
		}).then(result => {
			this.setState({technicians: result['hydra:member']})
		})
	}

	componentDidMount() {
		this.fetchTechnicians()
	}

	render() {
		const {
			manual,
			pages,
			technician,
			startDate,
			endDate,
		} = this.state;
		const {
			roles,
			user
		} = this.props
		const isTechnicien = (roles.includes("ROLE_TECHNICIEN_SAV") || roles.includes("ROLE_TECHNICO_COMMERCIAL")) && !roles.includes("ROLE_ADMIN")
		const isAdmin = roles.includes("ROLE_ADMIN")

		const {columns, load} = this;
		return (
			<div>
				<div className="animated fadeIn">
					<Card className="border-card">
						<CardHeader className="card-header-contract">
							<h2>Liste des entretiens par technicien</h2>
						</CardHeader>

						<div className="filtre-entretiens">
							{isAdmin &&
							<div style={{width: '220px', padding: '20px 15px', marginLeft: 15}}>
								<Select
									value={technician}
									placeholder="Filtrer par technicien"
									onChange={(value) => {
										this.onFilterChange('technician', value)
									}}
									options={this.state.technicians.map(item => ({value: item.id, label: item.name}))}
								/>
							</div>
							}
							{isTechnicien &&
								<div className="group-filter-less">
									<div>Technicien</div>
									<div className="filters-less box-shadow">{user.name}</div>
								</div>
							}
							<div style={{display: 'flex', alignItems: 'center', padding: '0 15px', marginLeft: 15}}>
								<span style={{marginRight: 10}}>Du : </span>
								<DateField
									value={startDate}
									onChange={
										(value) => {
											this.onFilterChange('startDate', value)
										}}
									className="form-control"
									isClearable
								/>
							</div>

							<div style={{display: 'flex', alignItems: 'center', padding: '0 15px'}}>
								<span style={{marginRight: 10}}>Au : </span>
								<DateField
									value={endDate}
									onChange={
										(value) => {
											this.onFilterChange('endDate', value)
										}}
									className="form-control"
									isClearable
								/>
							</div>
							<div className="compteur-entretien-tech" style={{alignItems: 'center'}}>
								<span style={{marginRight: 10}}>Nombres d'entretiens :  </span>
								<div className="bordure-entretien-tech">
									<div className="number-entretien-tech">{this.state.totalAppointment}</div>
								</div>
							</div>
						</div>

						<WHTable
							data={this.state.data}
							pages={pages}
							columns={columns()}
							loading={!this.state.loaded}
							manual={manual}
							onFetchData={load}
						/>
					</Card>
				</div>

			</div>
		);
	}
}

const mapStateToProps = state => ({
	roles: getUserRoles(state),
	user: getUser(state),
});

export default connect(
	mapStateToProps,
)(TechnicianAppointmmentList);


