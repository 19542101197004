import fetch from '@/utils/fetch';
import QueryBuilder from '../../../components/WHTable/queryBuilder';
import configApi from '../../../constants/ApiConfig';
import { HYDRA_MEMBER } from '@/constants';

const endPoints = '/api/prices';

export const search = data => {
	const query = QueryBuilder(data);

	return fetch(`${configApi.url}${endPoints}`, 'get', query);
};

export let lst = [];

export const init = async () => {
	const data = await search({filtered:[{id: 'pagination', value: false}]});
	lst = [...data[HYDRA_MEMBER]];
	return data;
};

export const calculator = async (materials = [], contract = null, list = lst) => {

	let amount = 0;
	if (contract == null) return amount;
	const contractId = contract.replace(/(.*)\/([0-9]*)/gm, '$2');
	if (!contractId) return amount;

	materials.map(material => {
		list.map(price => {
			if (material.type.id == price.equipment.id && contractId == price.levelService.id && material.type.id === 7) {
				let uniteComfort;
				material.nbrUnite = parseInt(material.nbrUnite)
				material.nbrUniteInt = parseInt(material.nbrUniteInt)
				if (contractId === '1') {

					if (material.nbrUnite) {
						let uniteComfortExtEss = list.find(material => material['@id'] === '/api/prices/equipment=34;levelService=1')
						let uniteComfortIntEss = list.find(material => material['@id'] === '/api/prices/equipment=36;levelService=1')
						if (material.nbrUnite === material.nbrUniteInt ) {
							amount += (uniteComfortIntEss.monthly + uniteComfortExtEss.monthly) * (material.nbrUnite - 1)
						}
						if (material.nbrUnite < material.nbrUniteInt ) {
							amount += (uniteComfortIntEss.monthly + uniteComfortExtEss.monthly) * (material.nbrUnite - 1) + (uniteComfortIntEss.monthly * (material.nbrUniteInt - material.nbrUnite))
						}
					}

				} else if (contractId === '2'){
					let uniteComfortExtCon = list.find(material => material['@id'] === '/api/prices/equipment=34;levelService=2')
					let uniteComfortIntCon = list.find(material => material['@id'] === '/api/prices/equipment=36;levelService=2')
					material.nbrUnite = parseInt(material.nbrUnite)
					material.nbrUniteInt = parseInt(material.nbrUniteInt)
					if (material.nbrUnite === material.nbrUniteInt ) {
						amount += (uniteComfortIntCon.monthly + uniteComfortExtCon.monthly) * (material.nbrUnite - 1)
					}
					if (material.nbrUnite < material.nbrUniteInt ) {
						amount += (uniteComfortIntCon.monthly + uniteComfortExtCon.monthly) * (material.nbrUnite - 1) + (uniteComfortIntCon.monthly * (material.nbrUniteInt - material.nbrUnite))
					}
				}
				amount +=  price.monthly;
			} else if (material.type.id == price.equipment.id && contractId == price.levelService.id) {
				amount += price.monthly;
			}
		});

	});

	return amount;
};

export const view = id => fetch(`${configApi.url}${endPoints}/${id}`, 'get');

export const create = data => fetch(`${configApi.url}${endPoints}`, 'post', data);

export const update = data => fetch(`${configApi.url}${endPoints}/${data.id}`, 'put', data);

export const remove = id => fetch(`${configApi.url}${endPoints}/${id}`, 'delete', {});
