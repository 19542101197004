import React, {Component} from "react";
import {Field} from "redux-form";
import PropTypes from "prop-types";
import {Card, Alert, CardBody, Button} from "reactstrap";

import {FieldTxt, FieldSelect} from "../../../../components/Form/wh-field";
import {email, numeric, required} from "../../../../components/Form/wh-validator";
import {connect} from "react-redux";
import {mapStateToProps} from "./ContractForm";
import {loadPostal} from "../../../../services/contract";
import * as sectorServices from "../../../../services/sectors";

class CustomerForm extends Component {
	state = {
		postal: [],
		sectors: [],
		loaded: false
	};

	async componentDidMount() {
		const postal = await loadPostal()
		const sector = await sectorServices.search({filtered: [{id: "sectorType", value: "technical"}]})
		await this.setState({postal: postal, sectors: sector["hydra:member"], loaded: true})
	}

	render() {
		const {
			error,
			next,
			isArchivedContract,
			userIsAdmin,
			require
		} = this.props;
		const fieldDisabled = (isArchivedContract && !userIsAdmin) || !!this.props.initialValues.contractSignature
		const disabledPostal = fieldDisabled || !this.state.loaded
		const req = require ? [required] : []

		const getSectorCodePostal = (postal) => {
			const tab = this.state.postal
			const secteur = tab.filter(function (value) {
				return JSON.stringify(value[4]).toLowerCase().indexOf(postal.toLowerCase()) !== -1;
			});
			const tabSecteurs = this.state.sectors
			if (secteur[0]) {
				const nord = tabSecteurs.filter(secteurs => secteurs.id === 5)
				const oise = tabSecteurs.filter(secteurs => secteurs.id === 8)
				const aisne = tabSecteurs.filter(secteurs => secteurs.id === 9)
				const somme = tabSecteurs.filter(secteurs => secteurs.id === 10)
				const normandie = tabSecteurs.filter(secteurs => secteurs.id === 7)
				const pasDeCalais = tabSecteurs.filter(secteurs => secteurs.id === 6);
				let equip
				switch (secteur[0][0]) {
					case "LILLE":
						this.props.currentValues.sector = nord[0]['@id']
						equip = nord[0].teams.filter(team => team.id === 17)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "AVESNES SUR HELPE":
						this.props.currentValues.sector = nord[0]['@id']
						equip = nord[0].teams.filter(team => team.id === 29)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "CAMBRAI":
						this.props.currentValues.sector = nord[0]['@id']
						equip = nord[0].teams.filter(team => team.id === 16)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "DOUAI":
						this.props.currentValues.sector = nord[0]['@id']
						equip = nord[0].teams.filter(team => team.id === 19)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "DUNKERQUE" :
						this.props.currentValues.sector = nord[0]['@id']
						equip = nord[0].teams.filter(team => team.id === 30)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "VALENCIENNES" :
						this.props.currentValues.sector = nord[0]['@id']
						equip = nord[0].teams.filter(team => team.id === 12)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "LENS" :
						this.props.currentValues.sector = pasDeCalais[0]['@id']
						equip = pasDeCalais[0].teams.filter(team => team.id === 18)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "CALAIS" :
						this.props.currentValues.sector = pasDeCalais[0]['@id']
						equip = pasDeCalais[0].teams.filter(team => team.id === 37)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "ST OMER" :
						this.props.currentValues.sector = pasDeCalais[0]['@id']
						equip = pasDeCalais[0].teams.filter(team => team.id === 32)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "MONTREUIL SUR MER" :
						this.props.currentValues.sector = pasDeCalais[0]['@id']
						equip = pasDeCalais[0].teams.filter(team => team.id === 31)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "BOULOGNE SUR MER" :
						this.props.currentValues.sector = pasDeCalais[0]['@id']
						equip = pasDeCalais[0].teams.filter(team => team.id === 33)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "BETHUNE" :
						this.props.currentValues.sector = pasDeCalais[0]['@id']
						equip = pasDeCalais[0].teams.filter(team => team.id === 11)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "ARRAS" :
						this.props.currentValues.sector = pasDeCalais[0]['@id']
						equip = pasDeCalais[0].teams.filter(team => team.id === 15)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case "Beauvais":
						this.props.currentValues.sector = oise[0]['@id']
						equip = oise[0].teams.filter(team => team.id === 38)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Clermont":
						this.props.currentValues.sector = oise[0]['@id']
						equip = oise[0].teams.filter(team => team.id === 39)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Compiegne":
						this.props.currentValues.sector = oise[0]['@id']
						equip = oise[0].teams.filter(team => team.id === 40)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Senlis":
						this.props.currentValues.sector = oise[0]['@id']
						equip = oise[0].teams.filter(team => team.id === 41)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Abbeville":
						this.props.currentValues.sector = somme[0]['@id']
						equip = somme[0].teams.filter(team => team.id === 42)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Amiens":
						this.props.currentValues.sector = somme[0]['@id']
						equip = somme[0].teams.filter(team => team.id === 43)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Montdidier":
						this.props.currentValues.sector = somme[0]['@id']
						equip = somme[0].teams.filter(team => team.id === 44)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Peronne":
						this.props.currentValues.sector = somme[0]['@id']
						equip = somme[0].teams.filter(team => team.id === 45)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Château Thierry":
						this.props.currentValues.sector = aisne[0]['@id']
						equip = aisne[0].teams.filter(team => team.id === 46)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Laon":
						this.props.currentValues.sector = aisne[0]['@id']
						equip = aisne[0].teams.filter(team => team.id === 47)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "St quentin":
						this.props.currentValues.sector = aisne[0]['@id']
						equip = aisne[0].teams.filter(team => team.id === 48)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "Soissons":
						this.props.currentValues.sector = aisne[0]['@id']
						equip = aisne[0].teams.filter(team => team.id === 49)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case "vervins":
						this.props.currentValues.sector = aisne[0]['@id']
						equip = aisne[0].teams.filter(team => team.id === 50)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break;
					case 27 :
						this.props.currentValues.sector = normandie[0]['@id']
						equip = normandie[0].teams.filter(team => team.id === 34)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case 14 :
						this.props.currentValues.sector = normandie[0]['@id']
						equip = normandie[0].teams.filter(team => team.id === 35)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
					case 76 :
						this.props.currentValues.sector = normandie[0]['@id']
						equip = normandie[0].teams.filter(team => team.id === 36)
						this.props.currentValues.teamTechnique = equip[0]['@id']
						break
				}
			}
		}

		return (
			<Card>
				<CardBody>
					<Field
						label="Civilité :"
						name="customer.civility"
						component={FieldSelect}
						validate={req}
						options={[
							{value: "Mme", name: "Mme"},
							{value: "M.", name: "M."}
						]}
						disabled={fieldDisabled}
					/>

					<Field
						label=" * Prénom :"
						id="field-form"
						name="customer.firstName"
						errorTxt="Vous devez indiquer un prénom"
						validate={req}
						component={FieldTxt}
						disabled={fieldDisabled}
						placeholder="Prénom"
					/>

					<Field
						label=" * Nom :"
						id="field-form"
						name="customer.lastName"
						component={FieldTxt}
						errorTxt="Vous devez indiquer un prénom"
						validate={req}
						type="text"
						disabled={fieldDisabled}
						placeholder="Nom"
					/>

					<Field
						label=" * Adresse :"
						id="field-form"
						validate={req}
						name="customer.address"
						component={FieldTxt}
						disabled={fieldDisabled}
						placeholder="Numéro Rue Ville"
					/>

					<Field
						label=" * Code postal :"
						id="field-form"
						validate={req}
						onChange={(e) => getSectorCodePostal(e.target.value)}
						name="customer.zipCode"
						component={FieldTxt}
						disabled={disabledPostal}
						type="number"
						placeholder="Code Postal"

					/>

					<Field
						label=" * Ville :"
						id="field-form"
						validate={req}
						name="customer.city"
						component={FieldTxt}
						disabled={fieldDisabled}
						placeholder="Ville"
					/>

					<Field
						label="Pays :"
						validate={req}
						name="customer.country"
						component={FieldSelect}
						disabled={fieldDisabled}
						options={[
							{value: "France", name: "France"},
							{value: "Belgique", name: "Belgique"}
						]}
					/>

					<Field
						label=" * Statut :"
						validate={req}
						name="customer.status"
						component={FieldSelect}
						options={[
							{value: "", name: "Choisissez le statut"},
							{value: "Locataire", name: "Locataire"},
							{value: "Propriétaire", name: "Propriétaire"}
						]}
					/>

					<Field
						label=" * Email :"
						id="field-form"
						name="customer.email"
						validate={[email, ...req]}
						component={FieldTxt}
						placeholder="mail.mail@mail.fr"
					/>

					<Field
						label=" * Mobile :"
						id="field-form"
						validate={[...req, numeric]}
						name="customer.mobile"
						component={FieldTxt}
						placeholder="0700000000"
					/>

					<Field
						label="Téléphone :"
						id="field-form"
						validate={[numeric]}
						name="customer.phone"
						component={FieldTxt}
						placeholder="0320000000"
					/>

					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{__html: error}}/>
						</Alert>
					)}

					<Button size="lg" block color="primary" onClick={next}>
						Suivant
					</Button>
				</CardBody>
			</Card>
		)
	}
}

CustomerForm.defaultProps = {
	error: null
};

CustomerForm.propTypes = {
	error: PropTypes.shape({}),
	next: PropTypes.func.isRequired,
	isArchived: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(CustomerForm);
