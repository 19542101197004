import React, {Component} from "react";
import * as AppointmentService from "@/services/appointment";
import * as Members from "@/services/member";
import moment from "moment";
import PropTypes from "prop-types";
import {Button, Modal, ModalHeader} from 'reactstrap';
import "./styles.scss";
import AppointmentForm from "../AppointmentForm";
import {getDatesFromTimeInterval} from '@/utils/dates';
import {toast} from "react-toastify";
import Swal from "sweetalert2";


class AppointmentList extends Component {
	constructor(props) {
		super(props)

		this.checkIfIndexValid.bind(this);
	}

	state = {
		loading: false,
		modalOpen: false,
		appointmentToEdit: null,
		appointmentToValidate: null,
		technicians: [],
		appointments: {
			past: [],
			future: [],
		},
	};

	fetchTechnicians = () => {
		Members.search({filtered: [{id: "roles", value: "TECHNICIEN_SAV"},{id: "enabled", value: true},{id: 'pagination', value: false}]}).then(result => {
			this.setState({technicians: result['hydra:member']})
		})
	}

	fetchData = async () => {
		const {contractId} = this.props;

		this.setState({loading: true})

		const result = await AppointmentService.search({
			filtered: [{id: "contract", value: contractId}]
		});

		const appointments = result["hydra:member"].reduce(
			({past, future}, item) => {
				const newFuture = [...future];
				const newPast = [...past];

				if (
					moment(item.startDate).isBefore(
						moment()
							.locale("fr")
							.utc()
					)
				) {
					newPast.push(item);
				} else {
					newFuture.push(item);
				}

				return {
					past: newPast,
					future: newFuture,
				};
			},
			{
				past: [],
				future: [],
			}
		);

		this.setState({
			appointments,
			loading: false,
		});
	};

	componentDidMount() {
		this.fetchData();
		this.fetchTechnicians();
	}

	onEdit = (appointment) => {
		this.setState({
			appointmentToEdit: appointment,
			modalOpen: true,
		})
	}

	onValidate = (appointment) => {
		this.setState({
			appointmentToValidate: appointment,
			modalOpen: true,
		})
	}

	onDelete = (id) => {

		Swal.fire({
			title: "Supprimer",
			text: "Êtes vous sûr de vouloir supprimer cet entretien ?",
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Supprimer',
			denyButtonText: 'Annuler',
		}).then(async (result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"L'entretien a bien été supprimé",
					'',
					'success'
				)
				await AppointmentService.remove(id)
				this.fetchData()
				this.props.loadEvent()
			} else if (result.isDenied) {
				Swal.fire(
					"L'entretien n'a pas été supprimé",
					'',
					'error'
				)
			}
		})

		/*const deleteConfirm = window.confirm('Êtes vous sûr de vouloir supprimer cet entretien ?')

		if (deleteConfirm) {
		  AppointmentService.remove(id)
		  .then(() => {
			toast.success('Entretien supprimé avec succès')
			this.fetchData()
		  })
		  .catch((error) => {
			error("Un problème est survenu")
		  })
		}*/
	}

	renderAppointmentTile = appointment => {
		const {maintenanceIndex, startDate, technician, id} = appointment;

		const printIndex = () =>
			`${appointment.maintenanceIndex.toString()}${
				maintenanceIndex === 1 ? "er" : "e"
			}`;

		const validate = () => {
			this.onValidate(appointment)
		}

		const edit = () => {
			this.onEdit(appointment)
		}

		const remove = () => {
			this.onDelete(id)
		}

		return (
			<div className="appointment-tile" key={appointment.id}>
				<div>
					<span className="maintenance-index">
						{printIndex()} entretien
					</span>
					<span style={{display: "flex", alignItems: "center"}}>
						<div className={`icon fa fa-${appointment.isValid ? 'calendar-check-o' : 'calendar'}`}
							 style={appointment.isValid ? {color: 'green'} : {}}/>
						{moment(startDate)
							.locale("fr")
							.format("Do MMMM YYYY")}
					</span>
					{technician && (
						<span style={{display: "flex", alignItems: "center"}}>
							<div className="icon fa fa-user"/>
							{technician.firstname} {technician.lastname}
						</span>
					)}
					<span/>
				</div>

				<div style={{display: 'flex', alignItems: 'center'}}>
					{!appointment.isValid && <div className="btn-edition fa fa-check" onClick={validate}/>}
					<div className="btn-edition fa fa-edit" onClick={edit}/>
					<div className="btn-edition fa fa-trash" onClick={remove}/>
				</div>
			</div>
		);
	};

	toggleModal = () => {
		this.setState({modalOpen: !this.state.modalOpen, appointmentToEdit: null, appointmentToValidate: null,})
	}


	checkIfIndexValid = (index) => {
		if (!index) return true

		const {appointments: {past, future}, appointmentToEdit} = this.state
		const appointments = [...past, ...future]
		const existingAppointmentWithIndex = appointments.find(
			item => (
				item.maintenanceIndex === index && !appointmentToEdit ||
				appointmentToEdit && appointmentToEdit.id !== item.id && item.maintenanceIndex === index
			)
		)

		return !existingAppointmentWithIndex
	}

	shouldScheduleNextAppointment = () => {
		const {appointments, appointmentToValidate} = this.state

		return appointmentToValidate ?
			[...appointments.future, ...appointments.past]
				.findIndex(
					item => item.maintenanceIndex === (appointmentToValidate.maintenanceIndex + 1)
				) === -1 : false;
	}

	onSubmit = async (values) => {
		const {appointmentToEdit, appointmentToValidate} = this.state
		let startDate
		let endDate

		if (values.startTime === "Matin"){
			startDate = moment(values.date).set({hour:8,minute:0,second:0,millisecond:0}).locale('fr').format('YYYY-MM-DD HH:mm:ss')
			endDate = moment(values.date).set({hour:12,minute:0,second:0,millisecond:0}).locale('fr').format('YYYY-MM-DD HH:mm:ss')
		}else{
			startDate = moment(values.date).set({hour:13,minute:0,second:0,millisecond:0}).locale('fr').format('YYYY-MM-DD HH:mm:ss')
			endDate = moment(values.date).set({hour:17,minute:0,second:0,millisecond:0}).locale('fr').format('YYYY-MM-DD HH:mm:ss')
		}
		const appointment = appointmentToEdit ? appointmentToEdit : appointmentToValidate
		const payload = {
			...appointment,
			contract: `/api/contracts/${this.props.contractId}`,
			startDate: startDate ? startDate : null,
			endDate: endDate ? endDate : null,
			maintenanceIndex: appointmentToValidate ? appointmentToValidate.maintenanceIndex : parseInt(values.maintenanceIndex),
			technician: values.technician,
		}

		const service = this.state.appointmentToEdit || this.state.appointmentToValidate ?
			AppointmentService.update : AppointmentService.create

		const successMsg = this.state.appointmentToEdit ?
			"Entretien mis à jour avec succès" : "Entretien créé avec succès"

		if (appointmentToValidate) {
			payload.isValid = true
			payload.isScheduled = true
		}

		try {
			await service(payload)

			if (this.shouldScheduleNextAppointment()) {
				const {
					startDate: nextStartDate,
					endDate: nextEndDate
				} = getDatesFromTimeInterval(new Date(values.nextDate), values.nextStartTime, values.nextEndTime)

				const nextAppointmentPayload = {
					contract: `/api/contracts/${this.props.contractId}`,
					startDate: nextStartDate,
					endDate: nextEndDate ? moment(nextEndDate).format('YYYY-MM-DD HH:mm:ss') : null,
					maintenanceIndex: appointmentToValidate.maintenanceIndex + 1,
				}

				await AppointmentService.create(nextAppointmentPayload)
			}

			await this.fetchData()
			this.props.loadEvent()
			this.toggleModal()
			toast.success(appointmentToValidate ? "Entretien validé avec succès" : successMsg)
		} catch (error) {
			toast.error("Un problème est survenu")
		}
	}

	getInitialValues = () => {
		const {appointmentToEdit, appointmentToValidate} = this.state

		if (appointmentToEdit) {
			const dateBefore = moment(appointmentToEdit.startDate).set({hour:12,minute:30,second:0,millisecond:0}).locale('fr')
			let startTime
			if (moment(appointmentToEdit.startDate).isBefore(dateBefore)){
				startTime = "Matin"
			}else{
				startTime = "Après-midi"
			}
			return {
				date: moment(appointmentToEdit.startDate).toDate(),
				startTime: startTime,
				maintenanceIndex: appointmentToEdit.maintenanceIndex.toString(),
				technician: appointmentToEdit.technician,
			}
		} else if (appointmentToValidate) {
			const nextDate = moment(appointmentToValidate.startDate).add(1, 'year')
			const dateBefore = moment(appointmentToValidate.startDate).set({hour:12,minute:30,second:0,millisecond:0}).locale('fr')
			let startTime
			if (moment(appointmentToValidate.startDate).isBefore(dateBefore)){
				startTime = "Matin"
			}else{
				startTime = "Après-midi"
			}
			return {
				date: moment(appointmentToValidate.startDate).toDate(),
				startTime: startTime,
				endTime: appointmentToValidate.endDate ? moment(appointmentToValidate.endDate).format('HH:mm') : null,
				technician: appointmentToValidate.technician,
				nextDate: nextDate.toDate(),
				nextStartTime: startTime,
				nextEndTime: null
			}
		} else {
			return {date: new Date(), maintenanceIndex: null, startTime: null, endTime: null, technician: null}
		}
	}

	render() {
		const {appointments, modalOpen, appointmentToEdit, appointmentToValidate, loading} = this.state;


		const modalTitle = appointmentToEdit ?
			`Modifier entretien #${appointmentToEdit.maintenanceIndex}`
			: appointmentToValidate ?
				`Validation entretien #${appointmentToValidate.maintenanceIndex}`
				:
				"Créer un entretien"

		const mustScheduleNextAppointment = this.shouldScheduleNextAppointment()

		const initialValues = this.getInitialValues()
		const appointmentsLength = appointments.future.length + appointments.past.length

		return (
			<>
				{loading && <div>Chargement ...</div>}
				{!loading &&
					<div className="appointment-list">
						<div>
							<h3>Entretien(s) à venir</h3>
							{appointments.future.map(this.renderAppointmentTile)}
							{appointments.future.length === 0 && (
								<div className="empty">Vide</div>
							)}
						</div>
						<div>
							<h3>Entretien(s) passé(s)</h3>
							{appointments.past.map(this.renderAppointmentTile)}
							{appointments.past.length === 0 && (
								<div className="empty">Vide</div>
							)}
						</div>
						<button type={"button"} className="btn btn-block btn-primary" onClick={()=>{this.toggleModal()}}>+ Ajouter un
							entretien
						</button>
					</div>
				}
				{
					modalOpen &&
					<Modal isOpen={modalOpen} toggle={this.toggleModal}>
						<ModalHeader toggle={this.toggleModal}>
							{modalTitle}
						</ModalHeader>

						<AppointmentForm
							onSubmit={this.onSubmit}
							technicians={this.state.technicians}
							initialValues={{
								...initialValues,
								technician: initialValues.technician ? initialValues.technician['@id'] : null
							}}
							checkIfIndexValid={this.checkIfIndexValid}
							submitting={loading}
							isValidation={appointmentToValidate !== null}
							mustScheduleNextAppointment={mustScheduleNextAppointment}
						/>
					</Modal>
				}
			</>
		);
	}
}

AppointmentList.propTypes = {
	contractId: PropTypes.number.isRequired,
};

export default AppointmentList;
