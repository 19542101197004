import React, { Component } from "react";
import { Form } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { FieldTxt } from "../../components/Form/wh-field";
import { ModalBody, ModalFooter, Alert } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { required } from "@/components/Form/wh-validator";

class SectorForm extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount = () => {};

	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					<Field
						label="Nom :"
						name="name"
						component={FieldTxt}
						type="text"
						validate={required}
					/>
					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{ __html: error }} />
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						<i className="fa fa-save" />
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

SectorForm = reduxForm({
	form: "SectorForm"
})(SectorForm);

export default SectorForm;
