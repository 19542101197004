import React from 'react'
import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import logo from '@/img/omeo_services_logo.png';

const styles = {
  textLeap: { marginBottom: 14 },
  bigTextLeap: { marginBottom: 23 },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10
  },
}

const PdfLetter = ({
  firstname,
  name,
  address,
  zipcode,
  city
}) => {
  return (
    <Document>
      <Page style={{ paddingHorizontal: 70, fontSize: 13 }}>
        <View style={styles.logoContainer}>
          <Image src={logo} style={{ width: 250, height: 110 }} />
        </View>
        <View style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 10,
          paddingBottom: 10, borderBottomWidth: 1 }}>
          <Text>1213 rue des Saules –Parc d’Activités du Mélantois</Text>
          <Text>59262 SAINGHIN EN MELANTOIS</Text>
          <Text>Standard: 03 59 64 00 60</Text>
          <Text>–03 61 62 3410</Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 30 }}>
          <View style={{ display: 'flex', flexDirection: 'column', fontSize: 12, marginTop: 10, lineHeight: 1 }}>
            <Text>{firstname} {name}</Text>
            <Text>{address}</Text>
            <Text>{zipcode} {city}</Text>
          </View>
        </View>

        <Text style={{ textDecoration: 'underline', marginBottom: 20 }}>Objet: Vos entretiens</Text>

        <Text style={styles.bigTextLeap}>Chère cliente, cher client,</Text>

        <Text style={styles.textLeap}>Vous avez récemment choisi de nous faire confiance pour l’entretien de vos appareils de chauffage et nous vous en remercions.</Text>

        <Text style={styles.textLeap}>Veuillez trouver joints à ce courrier vos documents à conserver avec soin.</Text>

        <Text style={styles.textLeap}>Vous recevrez dans un autre courrierl’échéancierde  vos  mensualités (si vous choisissez le paiement au mois).</Text>

        <Text style={styles.textLeap}>En cas de réclamation, révocation ou modification relative à ce contrat, vous pourrez adresser vos demandes à l’adresse suivante:</Text>

        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginVertical: 25 }}>
          <Text>SAS OMEO SERVICES</Text>
          <Text>Service mandats SEPA</Text>
          <Text>1213 Rue des Saules</Text>
          <Text>59262 Sainghin-en-Mélantois</Text>
        </View>

        <Text style={styles.bigTextLeap}>Ou par mail: j.lefebvre@omeo.com</Text>

        <Text style={styles.bigTextLeap}>Veuillez agréer, Madame, Monsieur, l'expression de nos salutations distinguées.</Text>

        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
          <Text>Justine Lefebvre</Text>
          <Text>Service Contrats d’Entretien</Text>
        </View>

        <View style={{ fontSize: 10, marginTop: 80, textAlign: 'center' }}>
          <Text>SAS au capital de 67 800€ - RCS Lille SIREN 824926679–APE 3514Z–TVA FR24824926901</Text>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLetter
