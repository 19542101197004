import pricesTypes from './types';
import { SET_SERVICES } from './constants';
import { SET_LOADED, SET_DATA, SET_PAGES, SET_EDITED, REMOVE_ITEM } from '../../redux/constants';
import { init, search, view, create, update, remove } from '../../../../services/catalog/service';
import { createAction } from 'redux-friends';
import formatDataForRedux from '@/utils/formatDataForRedux';

export const setServices = createAction(pricesTypes[SET_SERVICES]);
export const setServicesLoadedState = createAction(pricesTypes[SET_LOADED]);
export const setServicesList = createAction(pricesTypes[SET_DATA]);
export const setServicesPages = createAction(pricesTypes[SET_PAGES]);
export const setEditedService = createAction(pricesTypes[SET_EDITED]);
export const removeService = createAction(pricesTypes[REMOVE_ITEM]);

export const fetchServices = () => async dispatch => {
    try {
        const data = await init();
        dispatch(setServices(formatDataForRedux(data, 20)));
    } catch (error) {
        console.error('fetchServices', { error });
        throw new Error(error);
    }
};

export const loadServices = (state = {}) => async dispatch => {
    try {
        const data = await search(state);
        dispatch(setServices(formatDataForRedux(data, state.pageSize)));
    } catch (error) {
        console.error({ error });
    }
};

export const deleteService = toDelete => async dispatch => {
    try {
        await remove(toDelete);
        dispatch(removeService(toDelete));
    } catch (error) {
        console.error({ error });
        alert(
            'Il existe des dépendances sur cette grille tarifaire, elle ne peut donc pas être supprimée'
        );
    }
};

const createOrUpdate = (isCreating = true) => item => async dispatch => {
    try {
        await (isCreating ? create : update)(item);
        dispatch(loadServices());
    } catch (error) {
        console.error({ error });
    }
};

export const createService = createOrUpdate();
export const updateService = createOrUpdate(false);

export const fetchService = id => async dispatch => {
    try {
        const data = await view(id);
        dispatch(setEditedService(data));
    } catch (error) {
        console.error({ error });
    }
};
