import request from "superagent";
import { getJwtToken } from "./session";
import handleError from "./handleError";
import { redirectToLogin } from "./redirectTo";

const prepareValidationError = error => {
	if (!error) {
		return error;
	}

	if (error["hydra:description"]) {
		if (error.violations) {
			error._error = "";

			error.violations.map(el => {
				/**
				 * Todo : Problème de validation sur les champs d'entité liée
				 * Il trouve bien l'erreur mais lors du focus sur le champ, il ne parvient pas à le vider et
				 * provoque une erreur
				 * En attentant on remonte tous les messages, mais les champs ne sont pas colorés
				 */

				error[el["propertyPath"]] = el.message;
				error._error += `${el.message}<br />`;
			});
		} else {
			error._error = error["hydra:description"];
		}
	} else {
		if (error.code && error.code === 401) {
			if (error.message === "Bad credentials") {
				error._error =
					"Vous n’avez pas été reconnu. Merci de réessayer ou de contacter votre administrateur. ";
			}
		}
	}

	return error;
};

const fetch = (url, method = "GET", data = {}, files = {}) =>
	new Promise((resolve, reject) => {
		method = method.toUpperCase();

		const query = request(method, url);

		switch (method) {
			case "GET":
				query.query(data);
				break;

			case "POST":
			case "PUT":
			case "DELETE":
				query.send(data);
				break;

			case "UPLOAD":
				Object.keys(files).forEach(key => {
					const file = files[key];
					query.attach("file", file);
				});
				break;
		}
		let jwtToken;
		try {
			jwtToken = getJwtToken();
		} catch (error) {
			redirectToLogin();
		}

		if (jwtToken) {
			query.set("Authorization", `Bearer ${jwtToken}`);
		}

		query
			.then(data => {
				resolve(data.body);
			})
			.catch(error => {
				// toast.error('Une erreur inconnue est survenue');
				if (error.response) {
					// intercept and redirect to login
					handleError(error.response.body);
					console.error("error response", error);
					reject(prepareValidationError(error.response.body));
				} else {
					reject(error);
				}
			});
	});

export default fetch;
