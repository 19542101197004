import React, {Component} from "react";
import {Button, Card, CardHeader} from "reactstrap";
import WHTable from "@whatsonweb/table";
import "../../../styles";
import 'moment/locale/fr';
import * as AppointmentService from "../../../services/appointment";
import {redirectTo} from "../../../utils/redirectTo";
import {CONTRACTS} from "../../../constants/paths";
import moment from "moment";
import Moment from "react-moment";
import {toast} from "react-toastify";
import {generateCsv} from "@/utils/files";
import {create} from "../../../services/contract";
import {formatContractForSave} from "../../Contract/utils";
import {TMP_ID} from "../../../constants";
import {getContractsStatus, removeContractFromToSync} from "../../Contract/redux";
import Loading from "../../../components/Loading";
import {getIsSeller, getUserRoles} from "../../Authentication/redux";
import {getIsOnline} from "../../../components/OnlineStatus/redux";
import connect from "react-redux/lib/connect/connect";
import PropTypes from "prop-types";
import {ROLE_ADMIN} from "../../../constants/roles";
import Swal from "sweetalert2";


class Publipostage extends Component {

	state = {
		appointmentId: null,
		open: false,
		data: [],
		pages: null,
		loaded: true,
		table: null,
		archivePending: false,
	};


	columns = () => {
		return [
			{
				Header: "Nom",
				accessor: "contract.customer.lastName",
				renderInCsv: (value, data) => {
					const customer = data.contract ? data.contract.customer : null

					return customer ? customer.lastName : ''
				},
			},
			{
				Header: "Prénom",
				accessor: "contract.customer.firstName",
				renderInCsv: (value, data) => {
					const customer = data.contract ? data.contract.customer : null

					return customer ? customer.firstName : ''
				},
			},
			{
				Header: "Adresse",
				accessor: "contract.customer.address",
				renderInCsv: (value, data) => {
					const customer = data.contract ? data.contract.customer : null

					return customer ? customer.address : ''
				},
			},
			{
				Header: "Code postal",
				accessor: "contract.customer.zipCode",
				renderInCsv: (value, data) => {
					const customer = data.contract ? data.contract.customer : null

					return customer ? customer.zipCode : ''
				},
			},
			{
				Header: "Ville",
				accessor: "contract.customer.city",
				renderInCsv: (value, data) => {
					const customer = data.contract ? data.contract.customer : null

					return customer ? customer.city : ''
				},
			},
			{
				Header: "Contrat",
				accessor: "contract.id",
				Cell: row => (
					<div className="text-center">
						<Button
							className="bg-dark  text-white rounded"
							onClick={() =>
								redirectTo(CONTRACTS, row.value)
							}
						>Voir Contrat
						</Button>
					</div>
				),
				notInCsv: true,
			}
		]
	};

	reload = () => {
		this.load(this.state.table)
	}

	load = (state) => {
		this.setState({
			loaded: false,
			table: state
		});
		return AppointmentService.search({
			...state,
			filtered: [
				{id: "isScheduled", value: false},
				{id: "clientHasBeenContacted[lt]", value: 1},
				{id: 'isArchived', value: 0},
			]
		}).then(data => {
			this.setState(
				{
					data: []
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize
						),
						loaded: true,
					});
				}
			);
		});
	};

	onExport = async () => {

		try {
			const datas = await AppointmentService.search({
				filtered: [
					{id: "isScheduled", value: false},
					{id: "clientHasBeenContacted[lt]", value: 1},
					{id: "pagination", value: false}, {id: 'isArchived', value: 0}
				],
			})

			await generateCsv(datas['hydra:member'], this.columns(), `publipostage_${moment().format('YYYY/MM/DD')}`)
		} catch (error) {
			toast.error("Erreur lors de la génération de l'export")
		}
	}

	onArchive = () => {

		Swal.fire({
			title: "Archiver",
			text: 'Êtes-vous sûr de vouloir archiver cette liste de publipostage ?',
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Archiver',
			denyButtonText: 'Annuler',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					'la liste de publipostage a bien été archivé',
					'',
					'success'
				)
				this.setState({archivePending: true})
				AppointmentService.archivedAppointment()
				this.setState({archivePending: false})
			} else if (result.isDenied) {
				Swal.fire(
					"la liste de publipostage n'a pas été archivé",
					'',
					'error'
				)
			}
		})

		/*const confirmed = window.confirm('Êtes vous sûr de vouloir archiver la liste de publipostage ?')

		if (confirmed) {
			this.setState({archivePending: true})
			try {
				await AppointmentService.archivedAppointment()
				toast.success("Liste archivée avec succès")
			} catch (error) {
				toast.error("Un problème est survenu")
			}
		}
		this.setState({archivePending: false})*/
	}

	render() {
		const {
			manual,
			roles
		} = this.props;

		const {columns, load} = this;

		const isAdmin =
			roles &&
			(roles.indexOf(ROLE_ADMIN) > -1);

		return (

			<div>
				{this.state.archivePending && <Loading/>}
				<div className="animated fadeIn">
					<Card className="border-card">
						<CardHeader className="card-header-contract">
							<h2>Publipostage à effectuer</h2>
							{isAdmin && (
							<div className="card-actions">
								<Button
									className="button-export button-text"
									onClick={() =>
										this.onExport()
									}
								>
									<i className="fa fa-file fa-lg icone-button-text"> </i> Export
								</Button>
								<Button
									className="button-archive button-text"
									onClick={() =>
										this.onArchive()
									}
								>
									<i className="fa fa-archive fa-lg icone-button-text"> </i> Archiver
								</Button>
							</div> )}
						</CardHeader>
						{!this.state.archivePending && <WHTable
							data={this.state.data}
							pages={this.state.pages}
							columns={columns()}
							loading={!this.state.loaded}
							manual={manual}
							onFetchData={load}
						/>}
					</Card>
				</div>

			</div>
		);
	}
}

Publipostage.propTypes = {
	roles: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
	roles: getUserRoles(state),
});

export default connect(
	mapStateToProps
)(Publipostage);
