import React, {Component} from 'react'
import {ModalBody, ModalFooter} from 'react-bootstrap';
import LaddaButton from 'react-ladda/dist/LaddaButton';
import {reduxForm, Field} from 'redux-form';
import {DatePickerField, FieldSelect, FieldTxt, TimePickerField} from '../../../../components/Form/wh-field';
import {required} from '../../../../components/Form/wh-validator';
import {EXPAND_LEFT} from 'react-ladda/dist/constants';
import moment from 'moment';
import {getDatesFromTimeInterval} from '@/utils/dates';
import PropTypes from "prop-types";

const endTimeValidator = (value, values) => {
	if (!value || !values.startTime) {
		return undefined
	}

	const {startDate, endDate} = getDatesFromTimeInterval(new Date(), values.startTime, value)

	const test = moment(startDate).isBefore(endDate)

	return test ? undefined : "L'heure de fin doit être postérieure à l'heure de début"
}

const nextEndTimeValidator = (value, values) => {
	if (!value || !values.nextStartTime) {
		return undefined
	}

	const {startDate, endDate} = getDatesFromTimeInterval(new Date(), values.nextStartTime, value)

	const test = moment(startDate).isBefore(endDate)

	return test ? undefined : "L'heure de fin doit être postérieure à l'heure de début"
}

class AppointmentForm extends Component {
	indexValidator = (value) => {

		if (Number.isNaN(parseInt(value))) {
			return "L'index doit être un nombre"
		}

		const isIndexValid = this.props.checkIfIndexValid(parseInt(value))

		if (!isIndexValid) {
			return 'Un entretien existe déjà avec cet index'
		}

		return undefined
	}



	render() {
		const {handleSubmit, technicians, submitting, isValidation, mustScheduleNextAppointment} = this.props

		const matinAprem = [{
			name : "Choisissez un horaire",
			value : ""
		},{
			name : "Matin",
			value : "Matin"
		},{
			name : "Après-midi",
			value :  "Après-midi"
		}]
		return (
			<div className="form-horizontal">
				<ModalBody>
					{!isValidation && <Field
						name="maintenanceIndex"
						label="Index de l'entretien"
						component={FieldTxt}
						type="number"
						validate={[required, this.indexValidator]}
					/>}
					<Field
						name="technician"
						label="Technicien"
						component={FieldSelect}
						options={[{value: null, name: ''}, ...technicians.map(item => ({
							value: item['@id'],
							name: item.name
						}))]}
					/>
					<Field
						name="date"
						label="Date"
						component={DatePickerField}
						isClearable={true}
						validate={[required]}
					/>
					<Field
						name="startTime"
						label="Heures"
						component={FieldSelect}
						options={matinAprem}
						validate={[required]}
					/>
					{isValidation && mustScheduleNextAppointment && (
						<>
							<div style={{fontWeight: 'bold', marginTop: 35, marginBottom: 20, paddingLeft: 5}}>
								Prochain entretien
							</div>
							<Field
								name="nextDate"
								label="Date"
								component={DatePickerField}
								isClearable={true}
								validate={[required]}
							/>
							<Field
								name="nextStartTime"
								label="Heures"
								component={FieldSelect}
								options={matinAprem}
								validate={[required]}
							/>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						onClick={handleSubmit}
					>
						<i className="fa fa-save"/>
						&nbsp;{isValidation ? "Valider l'entretien" : "Enregistrer"}
					</LaddaButton>
				</ModalFooter>
			</div>
		)
	}

}

const formName = 'ContractAppointmentForm';

AppointmentForm.propTypes = {
	checkIfIndexValid: PropTypes.func.isRequired,
	technicians: PropTypes.array.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	isValidation: PropTypes.bool.isRequired,
	mustScheduleNextAppointment: PropTypes.bool.isRequired,
};

export default reduxForm({form: formName, touchOnChange: true})(AppointmentForm);
