import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';
import * as memberService from '../../services/member';
import * as sessionService from '@/utils/session';
import FormPassword from './FormPassword';
import { Row, Col } from 'reactstrap';

class MyPassword extends Component {
	state = {
		user: {},
	};

	async componentDidMount() {
		const user = sessionService.me();
		this.setState({ user });
	}

	submit = async values => {
		try {
			await memberService.myPassword(values);
			toast.success('Enregistrement réussi');
		} catch (error) {
			throw new SubmissionError(error);
		}
	};

	render() {
		return (
			<div className="animated fadeIn">
				<Row>
					<Col xs="12" sm="6">
						<FormPassword onSubmit={this.submit} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default MyPassword;
