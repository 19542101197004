import React, {Component} from "react";
import {Button, Card, CardHeader, Input} from "reactstrap";
import WHTable from "../../../components/WHTable/index";
import "../../../styles";
import 'moment/locale/fr';
import * as AppointmentService from "../../../services/appointment";
import {redirectTo} from "../../../utils/redirectTo";
import {CONTRACTS} from "../../../constants/paths";
import moment from "moment";
import Moment from "react-moment";
import {toast} from "react-toastify";
import AppointmentPopup from "./Popup/AppointmentPopup";
import Select from "react-select";
import * as teamService from "../../../services/team";
import {ROLE_ADMIN} from "../../../constants/roles";
import PropTypes from "prop-types";
import {getUserRoles} from "../../Authentication/redux";
import connect from "react-redux/lib/connect/connect";
import {exportAppointmentCSV} from "../../../services/contract/export";

const dateOptions = [{
	value: moment().add(1, 'M').format('DD-MM-YYYY'),
	label: "30 jours"
}, {
	value: moment().add(2, 'M').format('DD-MM-YYYY'),
	label: "60 jours"
}, {
	value: moment().format('DD-MM-YYYY'),
	label: "En retard"
}, {
	value: moment().add(1, 'd').format('DD-MM-YYYY'),
	label: "Dans les temps"
}]


class SuiviEntretien extends Component {

	state = {
		initialCall: true,
		appointmentId: null,
		open: false,
		data: [],
		pages: null,
		loaded: false,
		tableState: null,
		dataTeams: [],
		exportBtn: false,
		retardEstimate: 0,
		startDate: dateOptions[0],
		table : {
			customFilters: null,
		},
		tablePage: 1,
		loadPending: false,

	};


	componentDidMount() {
		this.managePageTable()
		this.loadTeams()
	}

	managePageTable = () => {
		let storagePage =  localStorage.getItem('suiviEntrienPageNumber')

		this.setState(
			{
				tablePage : parseInt(storagePage),
				loadPending: true
			}
		);
	}

	getFilters = () => {

		return [
			{id: 'order[startDate]', value: 'asc'},
			{id: "isScheduled", value: false},
			{id: "isValid", value: false},
			{id: "clientHasBeenContacted[gt]", value: 0},
		]
	}

	loadTeams = () => {
		return teamService.search({filtered :[{id: "sector.sectorType", value: "technical" }]}).then(data => {
			this.setState(
				{
					dataTeams: []
				},
				() => {
					this.setState({
						dataTeams: data["hydra:member"],
					});
				}
			);
		});
	}
	columns = () => {
		return [
			{
				Header: "Nom",
				accessor: "contract.customer.lastName",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value}
					</div>
				)
			},
			{
				Header: "Prénom",
				accessor: "contract.customer.firstName",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value}
					</div>
				)
			},

			{
				Header: "Téléphone",
				accessor: "contract.customer.mobile",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value}
					</div>
				)
			},
			{
				Header: "Adresse",
				accessor: "contract.customer.address",
				Cell: row => {
					const customer = row.original.contract ? row.original.contract.customer : undefined
					const customerAdress = customer ? `${customer.address} ${customer.city} ${customer.zipCode}` : ""
					return(
						<div className="text-center cell-cursor"
							 onClick={() =>
								 redirectTo(CONTRACTS, row.original.contract.id)
							 }
						>
							{ customerAdress }
						</div>
					)
				},
			},
			{
				Header: "Ville",
				accessor: "contract.customer.city",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value}
					</div>
				)
			},
			{
				Header: "Code postal",
				accessor: "contract.customer.zipCode",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value}
					</div>
				)
			},
			{
				Header: "Date du prochain entretien",
				accessor: "startDate",
				Cell: row => {
					let className = "text-center cell-cursor"
					const dateBefore = moment().set({hour:0,minute:0,second:0,millisecond:0}).locale('fr').utc()
					if (moment(row.original.startDate).isBefore(dateBefore)) {
						className = "text-center cell-cursor bg-danger"
					}
					return (
						<div className={className}
							 onClick={() =>
								 redirectTo(CONTRACTS, row.original.contract.id)
							 }
						>
							<Moment format="DD/MM/YYYY">{row.value}</Moment>
						</div>
					)
				},
				isDate: true,
			},
			{
				Header: "Numéro d'entretien",
				accessor: "maintenanceIndex",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value === 1 &&
						<p>{row.value}er entretien</p>
						}{row.value !== 1 &&
					<p>{row.value}e entretien</p>
					}

					</div>

				),
			},
			{
				Header: "Produits",
				accessor: "contract.contractInformation",
				Cell: row => (
					<div className="text-center cell-cursor"
						 onClick={() =>
							 redirectTo(CONTRACTS, row.original.contract.id)
						 }
					>
						{row.value.map((option) => (
							<p key={option.type.name}>{option.type.name}</p>
						))}
					</div>
				),
				minWidth: 150,
			},
			{
				Header: "Actions",
				Cell: row => (
					<div className="flex-row" style={{display: 'flex'}}>
						<div className="w-25">
							{row.original.clientHasBeenContacted === 1 &&
							<i className="fa fa-exclamation-circle fa-2x"/>
							}
							{row.original.countCall === 1 &&
							<i className="ml-1 fa fa-phone fa-2x" aria-hidden="true"></i>
							}
						</div>
						<div className="w-75">
							<Button
								className="btn-transparent text-dark"
								onClick={() => {
									this.refs.AppointmentPopup.handleOpen()
									this.setState({appointment: row.original})
								}

								}
							>
								<i className="fa fa-ellipsis-h fa-2x"/>
							</Button>
						</div>
					</div>
				),
			}
		]
	};

	reload = () => {
		this.setState({loaded : false})
		this.load(this.state.table)
	}

	load = async state => {

		let customFilters = state.customFilters ? state.customFilters : this.getCustomFiltersWithState(this.state)
		if (state.pageFilters) {
			this.setState({tablePage : 0})
		}

		if (this.state.loadPending === true) {
			await this.onLoad( state.pageFilters ? state.pageFilters : state.page + 1, state, customFilters)
		}


	};

	onPageChange = async page => {
		this.setState({loaded : false})
		localStorage.setItem('suiviEntrienPageNumber', page)
		this.setState({tablePage : page})
	}

	onLoad = (page, state, customFilters) => {
		if (this.state.startDate.label === "En retard"){
			AppointmentService.estimateRetard({
				...state,
				filtered: [...this.getFilters(), ...customFilters]
			}).then(r =>
				this.setState({
					retardEstimate: r
				})
			)
		}

		if (!this.state.loaded) {
			const filters = { filtered: [...this.getFilters(), ...customFilters, {id: "page", value: page ? page :  parseInt(localStorage.getItem('suiviEntrienPageNumber')) + 1}]}
			return AppointmentService.search(filters).then(data => {
				this.setState(
					{
						data: []
					},
					() => {
						this.setState({
							data: data["hydra:member"],
							pages: Math.ceil(
								data["hydra:totalItems"] / 20
							),
							loadPending: true,
							totalAppointment: data["hydra:totalItems"],
						});
					}
				);
				setTimeout(() => {
					this.setState({loaded: true})
				}, 1000)

			});
		}
	}

	onExport = async () => {
		try {
			this.setState({exportBtn : true})
			await exportAppointmentCSV({
				filtered: [
					...this.getFilters(),
					...this.getCustomFiltersWithState(),
				],
			})
			this.setState({exportBtn : false})
		} catch (error) {
			toast.error("Erreur lors de la génération de l'export")
		}
	}

	getCustomFiltersWithState(newState) {
		const state = newState ? newState : this.state
		const customFilters = []

		const {teams, startDate, firstName, lastName} = state

		if (startDate.label !== "Dans les temps") {
			customFilters.push({id: 'startDate[before]', value: startDate.value })
			if (startDate.label !== "En retard"){
				customFilters.push({id: 'startDate[after]', value: moment().format('DD-MM-YYYY'), })
			}
		}

		if (startDate.label === "Dans les temps") {
			customFilters.push({id: 'startDate[after]', value: startDate.value })
		}

		if (teams) {
			customFilters.push({id: 'contract.technician.team', value: teams.value})
		}

		if (firstName) {
			customFilters.push({id: 'contract.customer.firstName', value: firstName})
		}
		if (lastName) {
			customFilters.push({id: 'contract.customer.lastName', value: lastName})
		}

		return customFilters
	}

	filterReload = (newState) => {
		const {tableState} = newState
		const customFilters = this.getCustomFiltersWithState(newState)
		const newTableState = {
			...tableState,
			customFilters,
			pageFilters: 1
		}
		this.setState({loaded : false})
		this.load(newTableState)
	}

	onFilterChange = async (stateKey, value) => {
		const newState = {
			...this.state,
			[stateKey]: value,
		}
		await this.setState(newState)

		this.filterReload(newState)
	}

	render() {
		const {
			manual,
			roles,
		} = this.props;
		const {
			teams,
			pages,
			startDate,
			data,
		} = this.state;

		const {columns, load, onPageChange} = this;
		const isAdmin =
			roles &&
			(roles.indexOf(ROLE_ADMIN) > -1);

		if (!localStorage.getItem('suiviEntrienPageNumber')) {
			localStorage.setItem('suiviEntrienPageNumber', 0)
		}


		return (
			<div>
				<AppointmentPopup ref={"AppointmentPopup"} appointment={this.state.appointment} reload={this.reload}/>
				<div className="animated fadeIn">

					<Card className="border-card">
						<CardHeader className="card-header-contract">
							<h2>Suivi des entretiens à venir J-30 jours</h2>
							{isAdmin && (
								<div className="card-actions">
									<Button
										className="button-export button-text"
										disabled={this.state.exportBtn}
										onClick={() =>
											this.onExport()
										}
									>
										<i className="fa fa-file fa-lg icone-button-text"> </i> Export
									</Button>
								</div> )}
						</CardHeader>
						<div className="filtre-entretiens">
							<div className="compteur-entretien-tech">
								<span style={{marginRight: 5}}>Nom : </span>
								<Input
									className="bordure-select-entretien-tech"
									onChange={e => {
										this.onFilterChange("lastName", e.target.value)
									}}
								>
								</Input>
							</div>
							<div className="compteur-entretien-tech">
								<span style={{marginRight: 5}}>Prénom : </span>
								<Input
									className="bordure-select-entretien-tech"
									onChange={e => {
										this.onFilterChange("firstName", e.target.value)
									}}
								>
								</Input>
							</div>
							<div className="compteur-entretien-tech">
								<span style={{marginRight: 5}}>Entretien sous : </span>
								<Select
									className="bordure-select-entretien-tech"
									clearable={false}
									value={startDate}
									options={dateOptions}
									onChange={value => {
										this.onFilterChange("startDate", value)
									}}>
								</Select>
							</div>

							<div className="compteur-entretien-tech" style={{alignItems: 'center'}}>
								<span style={{marginRight: 5}} >Équipes : </span>
								<Select
									className="bordure-select-entretien-tech"
									value={teams}
									options={this.state.dataTeams ? this.state.dataTeams.map((team) => {
										return {value: team.id, label: team.name}
									}) : []}
									onChange={value => {
										this.onFilterChange("teams", value)
									}}
								>
								</Select>
							</div>
							{this.state.startDate.label === "En retard" &&
							<div className="compteur-entretien-tech" style={{alignItems: 'center'}}>
								<span style={{marginRight: 5}}>Estimation du nombre d’entretiens en retard :  </span>
								<div className="bordure-entretien-tech">
									<div className="number-entretien-tech" >{this.state.retardEstimate}</div>
								</div>
							</div>
							}
							<div className="compteur-entretien-tech" style={{alignItems: 'center'}}>
								<span style={{marginRight: 5}}>Nombres d'entretiens :  </span>
								<div className="bordure-entretien-tech">
									<div className="number-entretien-tech" >{this.state.totalAppointment}</div>
								</div>
							</div>
						</div>

						<WHTable
							data={data}
							pages={pages}
							columns={columns()}
							loading={!this.state.loaded}
							manual={manual}
							onFetchData={load}
							actualPage={this.state.tablePage}
							onPageChange={onPageChange}
						/>

					</Card>
				</div>

			</div>
		);
	}
}

SuiviEntretien.propTypes = {
	roles: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
	roles: getUserRoles(state),
});

export default connect(
	mapStateToProps
)(SuiviEntretien);

