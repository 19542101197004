import React, {stringify, useEffect, useState} from "react";
import WHTable from "../../components/WHTable";
import {Button, Card, CardBody, CardHeader, Modal, ModalHeader} from "reactstrap";
import * as EventServices from "../../services/eventAppointment"
import moment from "moment";
import Swal from "sweetalert2";
import EventAppointmentForm from "./EventAppointmentForm";
import Loading from "../../components/Loading";
import AppointmentList from "../Contract/Appointments/AppointmentList";

const EventAppointmentList = ({contract, local}) => {
	const [events, setEvents] = useState([])
	const [pending, setPending] = useState(true)
	const [loadingModal, setLoadingModal] = useState(false)
	const [modal, setModal] = useState(false)

	const load = async () => {
		const eventAppointment = await EventServices.search({
			filtered: [{id: "contract", value: contract.id}],
			pageSize: 10
		})
		setEvents(eventAppointment['hydra:member'])
		setPending(false)
	}

	useEffect(() => {
		load()
	}, [])

	const toggleModal = () => {
		setModal(!modal)
	}
	const onCreate = async (values) => {
		setLoadingModal(true)
		const event = {
			"description": values.description,
			"contract": contract['@id'],
			"appointment": values.appointment ? values.appointment : null,
			"status": 800,
			"created": moment()
		}
		await EventServices.create(event)
		await load()
		setLoadingModal(false)
		toggleModal()

	}

	return (
		<div>
			{modal &&
				<Modal isOpen={modal} toggle={toggleModal}>
					<ModalHeader toggle={toggleModal}>
						créer un évènement
					</ModalHeader>
					{loadingModal &&
						<Loading/>
					}
					<EventAppointmentForm
						contractId={contract.id}
						onSubmit={onCreate}
					/>
				</Modal>
			}
			<Card className="border-card shadow-card">
				<CardHeader className="card-header-contract">
					<h4 className="contract-form-title">Entretiens</h4>
				</CardHeader>
				{!local &&
					<>
						<CardBody>
							<AppointmentList
								loadEvent={() => load()}
								contractId={contract.id}
							/>
						</CardBody>
						<CardHeader className="card-header-contract">
							<h4 className="contract-form-title">Evènements</h4>
						</CardHeader>
						<CardBody>
							<div>
								<table className="table table-hover table-bordered">
									<thead>
										<th scope="col">Date</th>
										<th scope="col">évènement</th>
									</thead>
									<tbody>
									{!pending && events && events.map((event, key) => {
										const date = moment(event.created).format(' Do MMMM YYYY, HH:mm')
										let index = 0
										if (event.appointment) {
											index = event.appointment.maintenanceIndex
										}
										return (
											<>
												{event.description &&
													<tr className="table-primary" key={key}>
														<td>{date}</td>
														<td>{event.description}</td>
													</tr>
												}
												{event.status === 100 && !event.description &&
													<tr className="table-danger" key={key}>
														<td>{date}</td>
														<td>La personne n'a pas répondu au 1er appel</td>
													</tr>
												}
												{event.status === 200 && !event.description &&
													<tr className="table-danger" key={key}>
														<td>{date}</td>
														<td>La personne n'a pas répondu au 2ème appel, il est renvoyé au
															publipostage
														</td>
													</tr>
												}
												{event.status === 300 && !event.description &&
													<tr className="table-warning"  key={key}>
														<td>{date}</td>
														<td>l'entretien n'a pas pu etre planifié</td>
													</tr>
												}
												{event.status === 500 && !event.description &&
													<tr className="table-success" key={key}>
														<td>{date}</td>
														<td>L'entretien {index} a été crée</td>
													</tr>
												}
												{event.status === 700 && !event.description &&
													<tr className="table-success" key={key}>
														<td>{date}</td>
														<td>L'entretien {index} a été effectué</td>
													</tr>
												}
											</>
										)
									})
									}
									</tbody>
								</table>
							</div>
							<Button
								className="btn btn-block btn-primary"
								onClick={() => toggleModal()}
							> + Ajouter un évènement
							</Button>
						</CardBody>
					</>
				}
				{local &&
					<CardBody>
						<div className="text-center">
							<p>Synchronisez d'abord le contrat avant de planifier un entretien</p>
						</div>
					</CardBody>
				}
			</Card>
		</div>
	)
}

export default EventAppointmentList
