import React from 'react';
import PropTypes from 'prop-types';

const InfoBlock = ({ customer, bic, iban }) => (
        <table className="info-table" cellSpacing="0" cellPadding="0">
            <tbody>
                <tr>
                    <td className="bg-color" colSpan="3">
                        En signant ce formulaire de mandat, vous autorisez (A) SAS OMEO à envoyer
                        des instructions à votre banque pour débiter votre compte, et (B) votre
                        banque à débiter votre compte conformément aux instructions de SAS OMEO .
                        <br />
                        <br />
                        Vous bénéficiez du droit d’être remboursé par votre banque selon les
                        conditions décrites dans la convention que vous avez passée avec elle.
                        Toutedemande de remboursement doit être présentée dans les 8 semaines
                        suivant la date de débit de votre compte <br />
                        <br />
                    </td>
                </tr>
                <tr>
                    <td width="300px">Votre nom :</td>
                    <td colSpan="2" className="text-center bold">
                        {customer.lastName.toUpperCase()}
                    </td>
                </tr>
                <tr>
                    <td>Société :</td>
                    <td colSpan="2" className="text-center bold">
                        {customer.firstName.toUpperCase()}
                    </td>
                </tr>
                <tr>
                    <td className="bg-color nb-right" />
                    <td colSpan="2" className="bg-color nb-left info">
                        Nom / prénom / Société
                    </td>
                </tr>
                <tr>
                    <td>Votre adresse :</td>
                    <td colSpan="2" className="text-center bold">
                        {customer.address.toUpperCase()}
                    </td>
                </tr>
                <tr>
                    <td className="bg-color nb-right" />
                    <td colSpan="2" className="bg-color nb-left info">
                        Numéro et nom de la rue
                    </td>
                </tr>
                <tr>
                    <td className="bg-color" />
                    <td className="text-center bold">{customer.zipCode.toUpperCase()}</td>
                    <td className="text-center bold">{customer.city.toUpperCase()}</td>
                </tr>
                <tr>
                    <td className="bg-color nb-right" />
                    <td className="bg-color nb-right nb-left info"> Code postal</td>
                    <td className="bg-color nb-left info"> Ville</td>
                </tr>
                <tr>
                    <td colSpan="3" className="text-center bold">
                        {customer.country.toUpperCase()}
                    </td>
                </tr>
                <tr>
                    <td className="bg-color" />
                    <td colSpan="2" className="bg-color info">
                        Pays
                    </td>
                </tr>
                <tr>
                    <td rowSpan="2" className="bg-color">
                        Les coordonnéesde votre compte
                    </td>
                    <td colSpan="2" className="text-center bold">
                        {iban}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" className="info">
                        Numéro d’identification international du compte bancaire - IBAN
                        (International Bank Account Number)
                    </td>
                </tr>
                <tr>
                    <td className="bg-color" />
                    <td colSpan="2" className="text-center bold">
                        {bic}
                    </td>
                </tr>
                <tr>
                    <td className="bg-color" />
                    <td colSpan="2" className="bg-color info">
                        Code international d’identification de votre banque - BIC (Bank Identifier
                        Code)
                    </td>
                </tr>
                <tr>
                    <td className="nb-right"> Nom du créancier</td>
                    <td colSpan="2" className="bold nb-left">
                        SAS OMEO
                    </td>
                </tr>
                <tr>
                    <td className="bg-color nb-right" />
                    <td colSpan="2" className="bg-color nb-left info">
                        Nom du créancier
                    </td>
                </tr>
                <tr>
                    <td className="nb-right" />
                    <td colSpan="2" className="bold nb-left">
                        FR70222637398
                    </td>
                </tr>
                <tr>
                    <td className="bg-color nb-right" />
                    <td colSpan="2" className="bg-color nb-left info">
                        Identifiant du créancier
                    </td>
                </tr>
                <tr>
                    <td className="nb-right" />
                    <td colSpan="2" className="bold nb-left">
                        1213 rue des Saules
                    </td>
                </tr>
                <tr>
                    <td className="bg-color nb-right" />
                    <td colSpan="2" className="bg-color nb-left info">
                        Numéro et nom de la rue
                    </td>
                </tr>
                <tr>
                    <td className="bg-color" />
                    <td width="100px" className="bg-color">
                        <table>
                            <tbody>
                                <tr>
                                    <td width="10px" className="bold text-center">
                                        5
                                    </td>
                                    <td width="10px" className="bold text-center">
                                        9
                                    </td>
                                    <td width="10px" className="bold text-center">
                                        2
                                    </td>
                                    <td width="10px" className="bold text-center">
                                        6
                                    </td>
                                    <td width="10px" className="bold text-center">
                                        2
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td className="bold">SAINGHIN-EN-MELANTOIS</td>
                </tr>
                <tr>
                    <td className="bg-color" />
                    <td className="bg-color info">Code postal</td>
                    <td className="bg-color info">Ville</td>
                </tr>
                <tr>
                    <td className="nb-right" />
                    <td className="nb-left nb-right bold">FRANCE</td>
                    <td colSpan="6" className="nb-left bold" />
                </tr>
                <tr>
                    <td className="bg-color nb-right" />
                    <td className="bg-color nb-left nb-right">Pays</td>
                    <td colSpan="6" className="bg-color nb-left" />
                </tr>
            </tbody>
        </table>
    );

InfoBlock.propTypes = {
    bic: PropTypes.string.isRequired,
    customer: PropTypes.shape({}).isRequired,
    iban: PropTypes.string.isRequired,
};

export default InfoBlock;
