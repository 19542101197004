import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import PropTypes, {element} from 'prop-types';

import Header from './Header';
import Sidebar from './Sidebar';
import Breadcrumb from './Breadcrumb';
import Footer from './Footer/Footer';
import Routes from './Routes';
import Loading from '../../components/Loading';

import { hasJwtToken, isAuthenticated } from '@/utils/session';
import { getFamilyLoadedState, fetchFamily } from '../Catalog/Family/redux';
import { getEquipmentsLoadedState, fetchEquipments } from '../Catalog/Equipment/redux';
import { getPricesLoadedState, fetchPrices } from '../Catalog/Price/redux';
import { fetchContractsStatus, fetchOnGoingContracts, getStatusFilter } from '../Contract/redux/';
import {getUser, getUserRoles, setUser} from '../Authentication/redux';
import { getIsOnline } from '../../components/OnlineStatus/redux';
import { redirectToLogin } from '@/utils/redirectTo';
import { getServicesLoadedState, fetchServices } from '../Catalog/Service/redux';
import { getStatusFilterValue } from '../Contract/List/utils';


class Layout extends Component {

	constructor(props) {

		super(props);

		this.state = {
			roles: null,
		}
	}

	state = { loaded: false };

	hasToken = hasJwtToken();

	async componentDidMount() {
		await this.init();
	}

	init = async () => {
		if (this.props.isOnline) {
			await this.initOnline();
			toast.info('Les données sont chargées, vous pouvez passer en mode hors connexion');
		} else {
			this.initOffline();
		}
	};

	initOnline = async () => {
		const isGranted = await this.checkAuthentication();
		if (isGranted) {
			this.loadParams();
		} else {
			redirectToLogin();
		}
	};

	initOffline = () => {
		this.setState({ loaded: true });
		if (!this.hasToken) {
			redirectToLogin();
		}
	};

	checkAuthentication = async () => {
		let isGranted = false;
		if (!this.hasToken) {
			const me = await isAuthenticated();
			if (me) {
				this.setState({loaded: true});
				this.props.onSetUser(me);
				console.warn('autho ok');
				isGranted = true;
			}
			else {
				isGranted = false;
			}
		}
		else {
			try {
				const data = await isAuthenticated();
				if (data) {
					this.setState({loaded: true});
					this.props.onSetUser(data);
					console.warn('autho ok');
					isGranted = true;
				}
			} catch (error) {
				console.warn(error);
			}
		}
		return isGranted;
	};

	loadParams = async () => {
		await this.props.onLoadFamilies();
		console.warn('family ok');
		await this.props.onLoadEquipments();
		console.warn('equipments ok');
		await this.props.onLoadPrices();
		console.warn('prices ok');
		await this.props.onLoadContractsStatus();
		console.warn('status ok');
		await this.props.onLoadServices();
		console.warn('level ok');
	};

	render() {

		const isAdmin = this.props.roles.includes("ROLE_ADMIN")
		const scriptJira = document.createElement('script');
		scriptJira.setAttribute('src','https://whatsonio.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-9zew5j/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-FR&collectorId=04ea9f69');
		scriptJira.setAttribute('type','text/javascript')
		if (isAdmin) {
			document.head.appendChild(scriptJira);
		}

		const { loaded } = this.state;
		const { isOnline, familyLoaded, equipementLoaded, priceLoaded, serviceLoaded } = this.props;
		const isNotFullyLoaded =
			isOnline &&
			(!loaded || !familyLoaded || !equipementLoaded || !priceLoaded || !serviceLoaded);

		if (isNotFullyLoaded) return <Loading />;

		return (
			<div className="app">
				<Header />

				<ToastContainer position="top-center" hideProgressBar autoClose={3000} />
				<div className="app-body">
					<Sidebar {...this.props} />
					<main className="main">
						{/*<Breadcrumb />*/}
						<Routes />
					</main>
				</div>
				<Footer />
			</div>
		);
	}
}

Layout.propTypes = {
	onSetUser: PropTypes.func.isRequired,
	onLoadFamilies: PropTypes.func.isRequired,
	onLoadEquipments: PropTypes.func.isRequired,
	onLoadPrices: PropTypes.func.isRequired,
	onLoadServices: PropTypes.func.isRequired,
	onLoadContractsStatus: PropTypes.func.isRequired,
	isOnline: PropTypes.bool.isRequired,
	familyLoaded: PropTypes.bool.isRequired,
	equipementLoaded: PropTypes.bool.isRequired,
	priceLoaded: PropTypes.bool.isRequired,
	serviceLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
	isOnline: getIsOnline(state),
	familyLoaded: getFamilyLoadedState(state),
	equipementLoaded: getEquipmentsLoadedState(state),
	priceLoaded: getPricesLoadedState(state),
	serviceLoaded: getServicesLoadedState(state),
	statusFilter: getStatusFilter(state),
	roles: getUserRoles(state),
	user: getUser(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onSetUser: setUser,
			onLoadFamilies: fetchFamily,
			onLoadEquipments: fetchEquipments,
			onLoadPrices: fetchPrices,
			onLoadServices: fetchServices,
			onLoadContractsStatus: fetchContractsStatus,
			// onLoadContracts: fetchOnGoingContracts,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Layout);
