import fetch from "@/utils/fetch";
import QueryBuilder from "../../components/WHTable/queryBuilder";
import configApi from "../../constants/ApiConfig";
import {add, formatISO} from "date-fns";

const endPoints = "/api/contracts";

export const search = (data, initialPage)=> {
	const pageSize = data.filtered.find(x => x.id === 'pageSize')
	if (pageSize) data.pageSize = pageSize.value
	let query = QueryBuilder(data, initialPage);


	const signatureDate = query.signatureDate;

	if (signatureDate) {
		query = {
			...query,
			"signatureDate[strictly_before]": formatISO(
				add(signatureDate, {days: 1})
			),
			"signatureDate[after]": formatISO(signatureDate)
		};
	}

	delete query.signatureDate;

	return fetch(`${configApi.url}${endPoints}`, "get", query);
};

export const bySeller = id =>
	fetch(`${configApi.url}${endPoints}?seller=${id}`);
export const fetchStatus = async () =>
	(await fetch(`${configApi.url}${endPoints}/parameters`, "get")).status;

export const view = id => fetch(`${configApi.url}${endPoints}/${id}`, "get");

export const loadPostal = () => fetch(`${configApi.url}${endPoints}/postal`, 'get');

export const create = data =>
	fetch(`${configApi.url}${endPoints}`, "post", data);

export const update = data =>
	fetch(`${configApi.url}${endPoints}/${data.id}`, "put", data);

export const remove = id =>
	fetch(`${configApi.url}${endPoints}/${id}`, "delete");

export const valid = id =>
	fetch(`${configApi.url}${endPoints}/valid/${id}`, "get");

export const unValid = id =>
	fetch(`${configApi.url}${endPoints}/unvalid/${id}`, "get");

export const getCGV = id =>
	fetch(`${configApi.url}${endPoints}/get-cgv/${id}.pdf`, "get");

export const sendCGV = id =>
	fetch(`${configApi.url}${endPoints}/send-cgv/${id}`, "get");

export const sendContractDocuments = id =>
	fetch(`${configApi.url}${endPoints}/send-documents/${id}`, "get");

export const exportCsv = data =>
	fetch(`${configApi.url}${endPoints}/export-csv`, "post", data);

export const yousign = id =>
	fetch(`${configApi.url}${endPoints}/yousign/${id}`, "get");

export const yousignSepa = id =>
	fetch(`${configApi.url}${endPoints}/yousignSepa/${id}`, "get");

export const dashboard = data => {
	let query = QueryBuilder(data);
	return fetch(`${configApi.url}${endPoints}/dashboard-statistics`, "get", query);
}

export const attestationTVA = data => {
	return fetch(`${configApi.url}${endPoints}/fillTVA`, "post", data);
}

export const multiDelete = data => {
	return fetch(`${configApi.url}${endPoints}/multiDelete`, "post", data);
}

export const multiArchived = data => {
	return fetch(`${configApi.url}${endPoints}/multiArchived`, "post", data);
}

export const multiUnarchived = data => {
	return fetch(`${configApi.url}${endPoints}/multiUnarchived`, "post", data);
}

export const driveContract = id =>
	fetch(`${configApi.url}${endPoints}/driveDownload/contract/${id}`, "get");

export const driveSepa = id =>
	fetch(`${configApi.url}${endPoints}/driveDownload/sepa/${id}`, "get");

export const driveTVA = id =>
	fetch(`${configApi.url}${endPoints}/driveDownload/TVA/${id}`, "get");

export const contratGenerate = id =>
	fetch(`${configApi.url}/contracts/${id}.pdf`, "get");

export const sepaGenerate = id =>
	fetch(`${configApi.url}/contracts/mandat/${id}.pdf`, "get");

