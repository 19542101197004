import ERROR_ON_TOKEN from '@/constants/jwtMessages';
import { redirectTo } from './redirectTo';
import { LOGIN } from '@/constants/paths';
import store from '@/redux/store';
import { deleteJwtToken, login } from './session';
import { getCredentials } from '../modules/Authentication/redux';

const handleError = async (error) => {
	if (error && ERROR_ON_TOKEN.includes(error.message)) {
		deleteJwtToken();
		try {
			const { username, password } = getCredentials(store.getState());
			await login(username, password);
			window.location.reload()
		} catch (error) {
			redirectTo(LOGIN);
		}
	}
};
export default handleError;
