import React, {Component, Fragment} from 'react';
import {Form, ListGroup, ListGroupItem} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {FieldCheckbox} from '../../../components/Form/wh-field';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';


class FormSignPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	render() {
		const {
			error,
			handleSubmit,
			submitting,
			isContractValid,
			contractSignature,
			CGVSignature,
			sepaSignature,
			paymentType,
			isSepaValid,
			beginSign,
			TVAValid
		} = this.props;


		return (
			<Form onSubmit={handleSubmit(beginSign)} className="form-vertical custom-form">
				<ListGroup>
					{isContractValid && !contractSignature && (
						<Fragment>
							<ListGroupItem>
								<Field
									label="Contrat"
									id="contract"
									name="contract"
									value="1"
									disabled={true}
									type="checkbox"
									component={FieldCheckbox}
								/>
							</ListGroupItem>
						</Fragment>
					)}
					{contractSignature && (
						<Fragment>
							<ListGroupItem>
								<Field
									label="Contrat déja signé"
									id="contract"
									disabled={true}
									name="contract"
									value="0"
									type="checkbox"
									component={FieldCheckbox}
								/>
							</ListGroupItem>
						</Fragment>
					)}
					{paymentType === 'sepa' && !sepaSignature &&
						isSepaValid && (
							<ListGroupItem>
								<Field
									label="Mandat de prélèvement"
									id="sepa"
									name="sepa"
									value="1"
									disabled={true}
									type="checkbox"
									component={FieldCheckbox}
								/>
							</ListGroupItem>
						)}
					{sepaSignature && (
						<ListGroupItem>
							<Field
								label="Mandat de prélèvement déja signé"
								id="sepa"
								name="sepa"
								value="0"
								disabled={true}
								type="checkbox"
								component={FieldCheckbox}
							/>
						</ListGroupItem>
					)}
					{!CGVSignature &&
						<Fragment>
							<ListGroupItem>
								<Field
									label="CGV"
									id="cgv"
									name="cgv"
									value="1"
									type="checkbox"
									component={FieldCheckbox}
								/>
							</ListGroupItem>
						</Fragment>
					}
					{CGVSignature && (
						<Fragment>
							<ListGroupItem>
								<Field
									label="CGV déja signé"
									id="cgv"
									name="cgv"
									value="0"
									disabled={true}
									type="checkbox"
									component={FieldCheckbox}
								/>
							</ListGroupItem>
						</Fragment>
					)}
					{!TVAValid &&
					<Fragment>
						<ListGroupItem>
							<Field
								label="Attestation TVA"
								id="tva"
								name="tva"
								value="1"
								type="checkbox"
								component={FieldCheckbox}
							/>
						</ListGroupItem>
					</Fragment>
					}
					{TVAValid &&
						<Fragment>
							<ListGroupItem>
								<Field
									label="Attestation TVA déja signé"
									id="tva"
									name="tva"
									value="0"
									disabled={true}
									type="checkbox"
									component={FieldCheckbox}
								/>
							</ListGroupItem>
						</Fragment>
					}
				</ListGroup>
				<LaddaButton
					className="btn btn-success btn-ladda mt-3"
					loading={submitting}
					data-style={EXPAND_LEFT}
					type="submit"
				>
					<i className="fa fa-save"/>
					&nbsp;Commencez le(s) signatures
				</LaddaButton>
			</Form>
		)
	}
}

FormSignPopup = reduxForm({
	form: 'FormSignPopup'
})(FormSignPopup);

export default FormSignPopup
