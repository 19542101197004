import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getOneContractToSync, getOneContract } from '../Contract/redux';
import { redirectToDashboard } from '@/utils/redirectTo';

import ContractPreview from './Contract';
import SepaPreview from './Sepa';
import { SEPA, CONTRACT } from '../../constants';
import getParamIDFromProps from '../../utils/getParamIDFromProps';
import { isStartingWithUuidPrefix } from '@/utils/uuid';

import './index.scss';

export const Preview = ({
    contract,
    match: {
        params: { type },
    },
}) => {
    if (!contract) {
        redirectToDashboard();
    }

    const [Component, className] =
        type === SEPA ? [SepaPreview, SEPA] : [ContractPreview, CONTRACT];

    return <Component contract={contract} className={className} />;
};

Preview.propTypes = {
    contract: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
};

export const mapStateToProps = (state, ownProps) => {
    const paramID = getParamIDFromProps(ownProps);
    const getContract = isStartingWithUuidPrefix(paramID) ? getOneContractToSync : getOneContract;
    return {
        contract: getContract(state, paramID),
    };
};

export default connect(mapStateToProps)(Preview);
