import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '@/constants';

const formatDataForRedux = (data,pageSize = 20 ) => ({
    data: data[HYDRA_MEMBER],
    pages: Math.ceil(data[HYDRA_TOTAL_ITEMS] / pageSize),
    loaded: true,
	totalContract: data[HYDRA_TOTAL_ITEMS],
	pageSize: pageSize,
});

export default formatDataForRedux;
