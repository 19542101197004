import fetch from '@/utils/fetch';
import QueryBuilder from '../../components/WHTable/queryBuilder';
import configApi from '../../constants/ApiConfig';

const endPoints = '/api/customers';

export const search = data => {
    const query = QueryBuilder(data);

    return fetch(`${configApi.url}${endPoints}`, 'get', query);
};

export const view = id => fetch(`${configApi.url}${endPoints}/${id}`, 'get');

export const create = data => fetch(`${configApi.url}${endPoints}`, 'post', data);

export const update = data => fetch(`${configApi.url}${endPoints}/${data.id}`, 'put', data);

export const remove = id => fetch(`${configApi.url}${endPoints}/${id}`, 'delete');
