import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { logout } from '@/utils/session';

import PropTypes from 'prop-types';
import {destroy} from "../../../utils/storage";

class HeaderDropdownAccount extends Component {
    state = { dropdownOpen: false };

    toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

    logout = async () => {
		await logout();
		this.props.history.push('/login');
		destroy('persist:root');
		window.location.reload()

    };

    pushTo = path => () => this.props.history.push(path);

    render() {
		const userName = this.props
        return (
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle nav className='user-name'>
					<div>{this.props.userName}</div>
                    <i style={{fontSize: '17px'}} className="fa fa-user user-name-icone" />
                </DropdownToggle>

                <DropdownMenu right>
                    <DropdownItem header tag="div" className="text-center">
                        <strong>Mon compte</strong>
                    </DropdownItem>

                    <DropdownItem onClick={this.pushTo('/account/informations')}>
                        <i className="fa fa-user" />
                        <span>Mes infos</span>
                    </DropdownItem>
                    <DropdownItem onClick={this.pushTo('/account/password')}>
                        <i className="fa fa-lock" />
                        <span>Changer de mot de passe</span>
                    </DropdownItem>

                    <DropdownItem onClick={this.logout}>
                        <i className="fa fa-sign-out-alt" />
                        <span>Se deconnecter</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}
HeaderDropdownAccount.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default withRouter(HeaderDropdownAccount);
