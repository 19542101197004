import fetch from '@/utils/fetch';
import QueryBuilder from '../../../components/WHTable/queryBuilder';
import configApi from '../../../constants/ApiConfig';
import { HYDRA_MEMBER } from '@/constants';

const endPoints = '/api/equipment';

export const search = data => {

	const query = QueryBuilder(data);
	return fetch(`${configApi.url}${endPoints}`, 'get', query);
};

export let lst = [];

export const init = async () => {
	const data = await search({filtered : [{id: "pagination", value: false}]});
	lst = [...data[HYDRA_MEMBER]];
	return data;
};

export const getByFamily = (family, list = lst) => list.filter(el => el.family.id == family.id);

export const getById = (id, list = lst) => list.find(x => Number(x.id) === Number(id));

export const view = id => fetch(`${configApi.url}${endPoints}/${id}`, 'get');

export const create = data => fetch(`${configApi.url}${endPoints}`, 'post', data);

export const update = data => fetch(`${configApi.url}${endPoints}/${data.id}`, 'put', data);

export const remove = id => fetch(`${configApi.url}${endPoints}/${id}`, 'delete', {});
