import React from 'react';
import PropTypes from 'prop-types';
import EuroFormat from '@/components/EuroFormat/EuroFormat';

const EuroCellTemplate = ({ value }) => <EuroFormat value={value} />;

EuroCellTemplate.propTypes = {
    value: PropTypes.shape({}).isRequired,
};
export default EuroCellTemplate;
