import * as storage from './storage';
import fetch from './fetch';
import configApi from '@/constants/ApiConfig';
import { JWT_TOKEN } from '@/constants/';
import {destroy} from "./storage";

const endPoints = {
	login: '/login_check',
	logout: '/logout',
};

export const loginRequest = (username, password) =>
	fetch(`${configApi.url}${endPoints.login}`, 'post', {
		email: username,
		password,
	});

export const me = () => fetch(`${configApi.url}/api/me`, 'get');

export const setJwtToken = jwtToken => storage.save(JWT_TOKEN, jwtToken);

export const deleteJwtToken = () => storage.destroy(JWT_TOKEN);

export const getJwtToken = () => {
	let token;
	try {
		token = storage.get(JWT_TOKEN);
	} catch (error) {
		deleteJwtToken();
		token = null;
	}
	return token;
};

export const hasJwtToken = () => !!getJwtToken();

export const isAuthenticated = async () => {
	try {
		return await me();
	} catch (error) {
		return false;
	}
};

export const login = async (username, password) => {
	try {
		const response = await loginRequest(username, password);
		setJwtToken(response['token']);
	} catch (exception) {
		throw exception;
	}
};

export const logout = async () => {
	deleteJwtToken();
	destroy('persist:root');
	return Promise.resolve('disconnected');
};
