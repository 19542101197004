import React, {Component} from 'react';
import {Button, Modal, ModalHeader} from 'reactstrap';
import ExportForm from './ExportForm';
import Loading from '../../../components/Loading';
import * as contractExportService from "../../../services/contract/export";


class ExportPopup extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			data: {},
			loaded: true,
			loading : false
		};

	}

	handleOpen = () => {
		this.setState({
			open: true
		});

		this.setState({
			loaded: false
		});


	}

	handleClose = () => {
		this.setState({
			open: false,
		});
	}

	onSubmit = (data) => {
		data.status = 300;
		this.setState({
			loading: true
		})
		contractExportService.exportCSV(data).then((data) => {
			this.setState({
				loading: false
			})
		})
	}

	render() {
		const {className} = this.props;
		const {loaded, loading} = this.state;
		return (
				<Modal isOpen={this.state.open} className={className} fade={false}>
					<ModalHeader toggle={() => this.handleClose()}>Export</ModalHeader>
					{loading && <Loading/>}
					<ExportForm onSubmit={this.onSubmit} initialValues={this.state.data}/>

				</Modal>
		)
	}
}

export default ExportPopup;
