import {history} from '@/redux/store';
import {DASHBOARD, LOGIN, CONTRACTS, PAGE404, CONTRACTS_TO_SYNC, PRE_CONTRACT} from '../constants/paths';
import {composeRoute} from './getPath';

const _redirectTo = path => history.push(path);

const _redirectBuilder = path => () => _redirectTo(path);

export const redirectToLogin = _redirectBuilder(LOGIN);
export const redirectToDashboard = _redirectBuilder(DASHBOARD);
export const redirectToContract = _redirectBuilder(CONTRACTS);
export const redirectToPage404 = _redirectBuilder(PAGE404)
export const redirectToContractToSync = _redirectBuilder(CONTRACTS_TO_SYNC)
export const redirectToPreContrat= _redirectBuilder(PRE_CONTRACT)
export const redirectTo = (...args) => _redirectTo(composeRoute(...args));
