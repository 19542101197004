import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import PropTypes from 'prop-types';
import {contratGenerate, driveContract, driveSepa, driveTVA, getCGV, sepaGenerate} from "../../../services/contract";
import {redirectTo} from "../../../utils/redirectTo";
import {PREVIEW} from "../../../constants/paths";
import {CONTRACT, SEPA} from "../../../constants";


class DownloadPopup extends Component {
	state = {
		open: false,
		disabledContract: false,
		disabledCGV: false,
		loadingTVA: false,
		data: {},
	};

	handleOpen = data => this.setState({open: true, data});

	handleClose = () => {
		this.setState({open: false, data: {}});
		this.props.onClose && this.props.onClose(this.state.data);
	};

	downloadCgv = async () => {
		this.setState({disabledCGV: true})
		const customer = this.props.contract.customer
		const a = document.createElement("a");
		a.href = await getCGV(this.props.id);
		a.download = `${customer.firstName}_${customer.lastName}_CGV.pdf`;
		a.click();
		this.setState({disabledCGV: false})
	};

	downloadTVA = async () => {
		this.setState({loadingTVA: true})
		const customer = this.props.contract.customer
		const a = document.createElement("a");
		a.href = await driveTVA(this.props.id);
		a.download = `${customer.firstName}_${customer.lastName}_TVA.pdf`;
		a.click();
		this.setState({loadingTVA: false})
	}

	goToPreview = type => async () => {
		this.setState({
			disabledContract: true
		})
		const customer = this.props.contract.customer
		const a = document.createElement("a");
			if (this.props.contract.document && type === CONTRACT) {
				a.href = await driveContract(this.props.id);
				a.download = `${customer.firstName}_${customer.lastName}_contract.pdf`;
				a.click();
			}
			if (this.props.contract.document && type === SEPA) {
				a.href = await driveSepa(this.props.id);
				a.download = `${customer.firstName}_${customer.lastName}_sepa.pdf`;
				a.click();
			}
			if (type === CONTRACT && this.props.contract.contractSignature !== 'valid') {
				a.href = await contratGenerate(this.props.id);
				a.download = `${customer.firstName}_${customer.lastName}_contract.pdf`;
				a.click();
			}
			if (type === SEPA && this.props.contract.sepaSignature !== 'valid') {
				a.href = await sepaGenerate(this.props.id);
				a.download = `${customer.firstName}_${customer.lastName}_sepa.pdf`;
				a.click();
			}
			this.setState({
				disabledContract: false
			});
	}


	render() {
		const {
			className,
			contractSignature,
			CGVSignature,
			sepaSignature,
			handleSubmit,
			contract
		} = this.props;

		const {
			downloadCgv,
			goToPreview,
			downloadTVA
		} = this
		const {
			open,
			disabledContract,
			loadingTVA,
			disabledCGV
		} = this.state;
		const TVAFill = !!contract.attestationTVA

		return (
			<>


				<Modal size="sm" isOpen={open} className={className} fade={false}>
					<ModalHeader toggle={this.handleClose}>Documents</ModalHeader>

					<ModalBody>

						{contractSignature &&
							<div>
								<button
									onClick={goToPreview(CONTRACT)}
									disabled={disabledContract}
									className="btn btn-block btn-success btn-contract-form btn-lg"
								>
									télécharger le contrat
								</button>
							</div>
						}

						{sepaSignature &&
							<div>
								<button
									disabled={disabledContract}
									onClick={goToPreview(SEPA)}
									className="btn btn-block btn-success btn-lg btn-contract-form mt-3"
								>
									télécharger le mandat SEPA
								</button>
							</div>
						}
						{CGVSignature &&
							<div>
								<button
									onClick={downloadCgv}
									disabled={disabledCGV}
									className="btn btn-block btn-success btn-lg btn-contract-form mt-3"
								>
									télécharger les CGV
								</button>
							</div>
						}
						{TVAFill &&
							<div>
								<button
									onClick={downloadTVA}
									disabled={loadingTVA}
									className="btn btn-block btn-success btn-lg btn-contract-form mt-3"
								>
									télécharger l'attestation TVA
								</button>
							</div>
						}
							</ModalBody>
				</Modal>
			</>
		);
	}
}

DownloadPopup.defaultProps = {
	className: '',
	onClose: undefined,
	isSepaValid: false,
};
DownloadPopup.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	id: PropTypes.string.isRequired,
	isSepaValid: PropTypes.bool,
};

export default DownloadPopup


